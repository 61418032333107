<template>
  <main class="login">
    <section class="top">
      <i class="fa-solid fa-chevron-left fa-lg" @click="goBack"></i>
      <transition name="fade" mode="out-in">
        <div v-if="tab == 'login'" key="login">
          <span>New?</span>
          <a @click="changeTab('register')">Register</a>
        </div>
        <div v-else key="register">
          <span>Already have an account?</span>
          <a class="login" @click="changeTab('login')">Login</a>
        </div>
      </transition>
    </section>
    <transition name="fade" mode="out-in">
      <section class="form" v-if="tab == 'login'" key="login">
        <h1>Sign in</h1>
        <div class="platforms">
          <div v-for="plat in $platforms" :key="plat.name" :class="`platform ${plat.name}`" :style="{ 'background-color': plat.color }" @click="login(plat.name)">
            <i :class="plat.icon"></i>
            <span>Sign in using <b>{{plat.title}}</b></span>
          </div>
        </div>
        <div class="or">
          <hr />
          <span>or sign in with your account</span>
          <hr />
        </div>
        <div class="error" v-if="loginError">
          <i class="fa-solid fa-warning"></i>
          <span>{{ loginError }}</span>
        </div>
        <div class="inputs">
          <div class="input">
            <i class="fa-solid fa-user fa-lg"></i>
            <input type="text" name="username" placeholder="Username" v-model="username" />
          </div>
          <div class="input">
            <i class="fa-solid fa-lock fa-lg"></i>
            <input
              type="password"
              name="current-password"
              placeholder="Password"
              v-model="password"
              @keyup.enter="login"
            />
          </div>
          <div class="toggle" @click="extendedLogin = !extendedLogin">
            <i :class="(extendedLogin ? 'fa-solid fa-check-circle' : 'fa-regular fa-circle') + ' fa-lg'"></i>
            <span>Keep me logged in for 30 days *</span>
          </div>
        </div>
        <button @click="login">Login</button>
        <router-link class="forgot" to="/account-recovery"
          >Forgot password or username?</router-link
        >
      </section>
      <section class="form" v-else-if="tab == 'register'" key="register">
        <h1>Register</h1>
        <div class="platforms">
          <div class="platform discord" @click="login('discord')">
            <i class="fa-brands fa-discord fa-xl"></i>
            <span>Register using <b>Discord</b></span>
          </div>
        </div>
        <div class="or">
          <hr />
          <span>or register with your email</span>
          <hr />
        </div>
        <div class="error" v-if="registerError">
          <i class="fa-solid fa-warning"></i>
          <span>{{ registerError }}</span>
        </div>
        <div class="inputs">
          <div class="input">
            <i class="fa-solid fa-envelope fa-lg"></i>
            <input type="email" name="email" placeholder="Email" v-model="email" />
          </div>
          <div class="input">
            <i class="fa-solid fa-user fa-lg"></i>
            <input type="text" name="username" placeholder="Username" v-model="username" />
          </div>
          <div class="input">
            <i class="fa-solid fa-lock fa-lg"></i>
            <input type="password" name="new-password" placeholder="Password" v-model="password" />
          </div>
          <div class="input">
            <i class="fa-solid fa-lock fa-lg"></i>
            <input
              type="password"
              name="password"
              placeholder="Confirm password"
              v-model="passwordConfirm"
              @keyup.enter="register"
            />
          </div>
        </div>
        <button class="register" @click="register">Register</button>
      </section>
    </transition>
  </main>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    tab: "login",
    loginError: "",
    registerError: "",
    username: "",
    password: "",
    extendedLogin: false,
    passwordConfirm: "",
    email: "",
  }),
  methods: {
    goBack() {
      /*let redirectPath = this.$store.state.redirectPath;
      this.$store.commit("setRedirectPath", null);
      this.$router.push(redirectPath || "/");*/
      this.$router.push("/");
    },
    changeTab(to) {
      if (to === "register") {
        this.tab = "register";
        this.password = "";
        this.loginError = "";
        this.$router.push({ query: { tab: "register" } });
      } else {
        this.tab = "login";
        this.registerError = "";
        this.$router.push({ query: { tab: "login" } });
      }
    },
    login(platform) {
      let platformNames = this.$platforms.map(plat => plat.name);
      if (typeof platform === "string" && platformNames.includes(platform)) {
        //console.log(platformNames);
        //console.log("login with platform", platform);
        window.location.href = this.$api(`/user/${platform}`);
      } else {
        //console.log("Username login");
        if (!this.username) return this.loginError = "Please enter a username";
        if (!this.password) return this.loginError = "Please enter a password";
        axios
          .post(this.$api("/user/login"), {
            username: this.username,
            password: this.password,
            extended: this.extendedLogin,
          })
          .then((response) => {
            //console.log("login response", response);
            this.$store.commit("setToken", response.data.token);
            this.$store.commit("setUser", response.data.user);
            this.$router.push("/profile");
          })
          .catch((error) => {
            console.log("login error", error);
          });
      }
    },
    register() {
      if (this.password !== this.passwordConfirm) return this.registerError = "Passwords do not match";
      if (!this.email) return this.registerError = "Please enter an email";
      if (!this.username) return this.registerError = "Please enter a username";
      axios
        .post(this.$api("/user/register"), {
          username: this.username,
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          console.log("register response", response);
          this.$router.push(`/register-success?email=${this.email}`);
        })
        .catch((error) => {
          console.log("register error", error);
        });
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (to.query.tab === "register") {
          this.tab = "register";
        } else {
          this.tab = "login";
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>